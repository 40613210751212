<template>
  <v-sheet style="width: 100vw;">
    <v-btn :v-tooltip="$t('back')" style="position: absolute; left: 10px;" class="mt-2" text v-if="from" @click="backClicked()">
      <v-icon>
        mdi-arrow-left 
      </v-icon>
    </v-btn>
    <div class="toolbar">
      <v-btn v-tooltip="$t('edit_time_setting')" class="mt-4"  @click="show_editor_settings = true" small rounded>
        <v-icon>
          mdi-timer-cog-outline
        </v-icon> 
      </v-btn>
      <v-btn 
        small 
        rounded 
        :text="!!chosen_object"
        class="my-4 ml-10"
        @click="SET_CHOSEN_OBJECT(null)"
        :color="!chosen_object ? 'primary' : ''"
      >
        <v-icon>mdi-cursor-default-outline</v-icon>
      </v-btn>
      <v-btn
        small 
        rounded
        :text="chosen_object != 'arrow'"
        class="my-4"
        @click="SET_CHOSEN_OBJECT(chosen_object == 'arrow' ? null : 'arrow')"
        :color="chosen_object == 'arrow' ? 'primary' : ''"
      >
        <v-icon>mdi-arrow-top-right</v-icon>
      </v-btn>
      <v-btn 
        small 
        rounded
        :text="chosen_object != 'line'"
        class="my-4"
        @click="SET_CHOSEN_OBJECT(chosen_object == 'line' ? null : 'line')"
        :color="chosen_object == 'line' ? 'primary' : ''"
      >
        <v-icon>mdi-vector-line</v-icon>
      </v-btn>
      <v-btn 
        small 
        rounded
        :text="chosen_object != 'free'"
        class="my-4"
        @click="SET_CHOSEN_OBJECT(chosen_object == 'free' ? null : 'free')"
        :color="chosen_object == 'free' ? 'primary' : ''"
      >
        <v-icon>mdi-gesture</v-icon>
      </v-btn>
      <v-btn
        small
        rounded
        :text="chosen_object != 'ellipse'"
        class="my-4"
        @click="SET_CHOSEN_OBJECT(chosen_object == 'ellipse' ? null : 'ellipse')"
        :color="chosen_object == 'ellipse' ? 'primary' : ''"
      >
        <v-icon>mdi-ellipse-outline</v-icon>
      </v-btn>
      <v-btn 
        small 
        rounded
        :text="chosen_object != 'text'"
        class="my-4"
        @click="SET_CHOSEN_OBJECT(chosen_object == 'text' ? null : 'text')"
        :color="chosen_object == 'text' ? 'primary' : ''"
      >
        <v-icon>mdi-format-text</v-icon>
      </v-btn>
      <v-btn
        small
        rounded
        text
        class="my-4 ml-8"
        @click="addSlowdown()"
      >
        <v-icon>mdi-tortoise</v-icon>
      </v-btn>
      <v-btn class="ml-10" style="align-self: center;" @click="saveClip" small color="primary">
        {{$t('save_clip')}}
      </v-btn>
      <v-btn class="my-5 ml-2" small @click="startExporting()">
        <v-icon>mdi-download</v-icon>
        {{ $t('exports.download') }}
      </v-btn>
      <share-btn v-if="clip" @save="saveClip" size="small" class="ml-2" resource_type="clip" style="align-self: center;" :resource_id="clip.id"></share-btn>
      <choose-folder-btn @input="addClipToFolder($event)" class="my-5 ml-2" :small="true"></choose-folder-btn>
    </div>
    <div class="editor-main" style="justify-content: center; display: flex; width: 100%;">
      <div style="display: flex; flex-direction: column;">
        <div
          class="editor-main-body"
          :style="playerCont"
          style="display: flex; flex-direction: column; align-self: center;"
        >
          <EditorPlayer
            ref="editorPlayer"
            :mutable="true"
            @action="pause()"
            @shapeAdded="shapeAdded($event)"
            :isEditor="true"
            :chosenObject="chosen_object"
            :style="playerContStyle"
            :dimensions="playerCont"
            :options="options"
            :key="playerKey"
            :color="new_color"
            @input="SET_OBJECTS($event)"
            v-if="!loading && !!options"
          >
          </EditorPlayer> 
        </div>
        <div>
          <bar :hideRate="true"></bar>
        </div>
      </div>
      <v-sheet class="editor-main-sidebar">
        <div style="flex-grow: 1;">
          <div v-if="(selected_shape_name || chosen_object) && selected_shape.type !== 'text'" :style="{backgroundColor: new_color, height: '30px', width: '30px', borderRadius: '50%', border: '2px dashed black', margin: '0 auto'}">
          </div>
          <v-color-picker
            v-show="(selected_shape_name || chosen_object) && selected_shape.type !== 'text'"
            :swatches="colors" 
            show-swatches
            hide-inputs  
            hide-canvas
            hide-sliders
            @update:color="colorEdited($event)"
            :value="selected_shape_name && selected_shape ? selected_shape.stroke : new_color"
          ></v-color-picker>
          <div
            v-if="showEditor('text')"
          >
            <v-textarea
              v-prevent-keys
              solo
              :value="selected_shape_name ? selected_shape.text : new_text"
              @input="objectEdited($event, 'text')"
            ></v-textarea>
          </div>
          <div
            v-if="showEditor('strokeWidth')"
          >
            <v-select
              solo 
              :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 16, 20, 30, 40]"
              dense
              prepend-icon="mdi-format-line-weight" 
              label="Stroke width"
              :value="selected_shape_name ? selected_shape.strokeWidth : new_stroke_width"
              @input="objectEdited($event, 'strokeWidth')" 
            ></v-select>
          </div>
          <div
          v-if="showEditor('style')"
          >
            <v-select
              solo 
              :items="['dashed', 'full']"
              dense
              prepend-icon="mdi-border-style" 
              label="Style"
              :value="fullOrDash"
              @input="objectEdited($event, 'dash')" 
            ></v-select>
          </div>
          <div
            v-if="showEditor('fontSize')"
          >
            <v-select
              solo
              :items="[20, 30, 40, 50, 60, 70, 80, 90, 100, 120, 140, 160]"
              dense
              prepend-icon="mdi-format-size"
              label="Font size"
              :value="selected_shape_name ? selected_shape.fontSize : new_font_size"
              @input="objectEdited($event, 'fontSize')" 
            ></v-select>
          </div>
          <div style="text-align: center;" v-if="selected_shape_name">
            <v-btn @click="$refs.editorPlayer.removeFocusedShape()" class="mt-4" x-small outlined>
              <v-icon size="20" class="mr-3">
                mdi-delete-outline
              </v-icon>
              (del)
            </v-btn>
          </div>
          <!-- <div
            v-if="showEditor('fillEnabled')"
          >
            <v-switch
              :label="$t('graphics.fill_enabled')"
              :value="selected_shape_name ? selected_shape.fillEnabled : new_fill_enabled"
              @change="objectEdited($event, 'fillEnabled')" 
            ></v-switch>
          </div>  -->
          <div
            class="editor-main-sidebar-cont"
            v-if="layer_id && !selected_shape_name && !chosen_object"
          >
            <div 
              class="editor-main-sidebar-cont-item"
              v-if="editingSlowdon"
            >
              <div>
                {{ $t('speed') }}
                <v-select :items="[0.1, 0.2, 0.5, 2, 4]"
                  :value="this.fullLayerEdited.speed"
                  @input="speedEdited($event)"
                ></v-select> 
              </div>
              <div small style="display: flex; justify-content: center;">
                <v-btn outlined color="error" @click="deleteLayer()" small class="mb-4" style="mx-auto">
                  <v-icon class="mr-3">mdi-delete-outline</v-icon>
                  {{ this.fullLayerEdited.type != 'slowdown' ? $t('clip.delete_layer') : $t('clip.delete_slowdown') }}
                </v-btn>
              </div>
            </div>
            <div
              class="editor-main-sidebar-cont-item"
              v-if="editingStill"
            >
              <div>
                Pause duration - {{fullLayerEdited.duration || 'ထ'}} s
                <v-switch :input-value="!fullLayerEdited.duration" @change="$event ? durationEdited(null) : durationEdited(5)" :label="$t('layer_stays_on')">
                  Stays on
                </v-switch>
                <v-slider
                  :label="$t('pause_duration')"
                  v-if="fullLayerEdited.duration"
                  @input="durationEdited($event)"
                  min="1"
                  max="15"
                  :hint="fullLayerEdited.duration + ' s'"
                  :value="this.fullLayerEdited.duration"
                > </v-slider>
              </div>
              <div small style="display: flex; justify-content: center">
                <v-btn @click="deleteLayer()" small style="mx-auto">
                  {{ this.fullLayerEdited.type != 'slowdown' ? $t('clip.delete_layer') : $t('clip.delete_slowdown') }}
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </div>
            </div> 
          </div>
        </div>
      </v-sheet>
    </div>
    <div style="width: 95vw">
      <editor-bar></editor-bar>
    </div>
    <editor-settings
      :show="show_editor_settings"
      v-model="range"
      @close="show_editor_settings = false"
      @input="handleRangeChange($event)"
    ></editor-settings>
    <v-dialog max-width="800" v-model="exporting">
      <Export :key="exporting" v-if="clip" :clip_id="clip ? clip.id : ''" @close="exporting = false;"></Export>
    </v-dialog>
  </v-sheet>
</template>

<script>
import EditorPlayer from './EditorPlayer.vue'
import EditorSettings from './EditorSettings.vue'
import EditorBar from './EditorBar.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { v4 as uuidv4 } from 'uuid';
import Bar from '../player/Bar.vue'
import ChooseFolderBtn from '../../folder/ChooseFolderBtn.vue'
import ShareBtn from '@/components/share/ShareBtn'
import Export from '@/components/video/export/Export.vue'

export default {
  components: { ShareBtn, EditorPlayer, Bar, EditorSettings, EditorBar, ChooseFolderBtn, Export },
  data: () => ({
    playerKey: 0, // keep
    loading: true, // keep
    mounted: false,
    range: [0, 0],
    show_editor_settings: false,
    from: null,
    delete_if_no_graphics: false,
    clip_id: null,
    colors: [
      // First Nested List
      [
        "#000000",  // Black
        "#27AE60",  // Green
        "#FF6B6B",  // Coral
        "#3498DB",  // Blue
        "#F39C12",  // Yellow
      ],
      // Second Nested List
      [
        "#FFFFFF",  // White
        "#9B59B6",  // Purple
        "#E74C3C",  // Red
        "#1ABC9C",  // Turquoise
        "#F1C40F",  // Gold
      ],
      // Third Nested List
      [
        "#2ECC71",  // Emerald
        "#8E44AD",  // Violet
        "#E67E22",  // Orange
        "#2980B9",  // Ocean Blue
        "#D35400"   // Pumpkin
      ],
    ],
    he: window.innerHeight,
    wi: window.innerWidth,
    chosen_folder: null,
    copied_item: null,
    exporting: false
  }),
  shortcuts: {
    keydown: function (e) {
      if(e.key === 'c' && (e.metaKey || e.ctrlKey)) this.copyItem()
      if(e.key === 'v' && (e.metaKey || e.ctrlKey)) this.pasteItem()
    }
  },
  created() {
    let clip_id = this.$route.query.clip_id

    this.clipById(clip_id)
      .then(e => {
        let options = {};
        options.source = e.data.mp4_url || e.data.video_url || e.data.original_url
        options.mimeType = e.data.video_type == 'hls' ? 'application/x-mpegURL' : 'video/mp4'
        options.poster = e.data.thumb_url
        options.starttime = e.data.starttime
        options.endtime = e.data.endtime

        if(this.$route.query.seek) {
          options.start_and_pause_at = this.$route.query.seek
        }

        this.SET_OPTIONS(options)
        this.SET_CLIP(e.data)
        this.SET_LAYERS(e.data.graphics?.layers || [])
        this.SET_OBJECTS(e.data.graphics?.objects || [])
        this.SET_ORIGINAL_VIDEO_WIDTH(e.data.graphics?.original_video_width)

        // Ticks
        if(!this.clip.endtime) {
          this.range = [5, 5]

          this.SET_OPTIONS({
            ...options,
            starttime: Math.max(e.data.starttime - 5, 0),
            endtime: e.data.starttime + 5
          })
        } 
 
        this.$nextTick(() => {
          this.mounted = true
          this.loading = false
        })
      })
      .catch(e => this.error(e))

      window.addEventListener("resize", () => { 
        this.he = window.innerHeight
        this.wi = window.innerWidth
        this.REFRESH_EDITOR_CONT_ITEM_WIDTH()
       });
  },
  mounted() {
    if(this.$route.query.from) {
      this.from = this.$route.query.from
    }

    if(this.$route.query.delete_if_no_graphics) {
      this.delete_if_no_graphics = true
    }

    this.$nextTick(() => {
      this.REFRESH_EDITOR_CONT_ITEM_WIDTH()
    })
  },
  computed: {
    ...mapGetters('player', [
      'currentTime',
      'accurateCurrentTime',
      'playbackRate',
      'player',
      'playing'
    ]),
    ...mapGetters('draw', [
      'layers',
      'objects',
      'handled_layers',
      'clip',
      'options',
      'new_color',
      'selected_shape_name',
      'selected_shape',
      'original_video_width',
      'pause_timeout',
      'chosen_object',
      'layer_id',
      'new_stroke_width',
      'new_text',
      'new_fill_enabled',
      'new_font_size',
      'new_style',
      'editor_cont_item',
      'dont_clear'
    ]),
    editingSlowdon() {
      return this.layers?.find(i => i.id == this.layer_id)?.type == 'slowdown'
    },
    editingStill() {
      return this.layers?.find(i => i.id == this.layer_id)?.still
    },
    fullLayerEdited() {
      return this.layers?.find(i => i.id == this.layer_id)
    },
    playerCont() {
      let he = this.he
      let wi = this.wi

      if(wi > he) {
        he = he - 260
        return {
          width: he * 16 / 9,
          height: he
        }
      } else {
        return {
          width: wi,
          height: wi * 9 / 16
        }
      }
    },
    playerContStyle() {
      return {
        height: this.playerCont.height + 'px',
        width: this.playerCont.width + 'px'
      }
    },
    fillStyle() {
      const bar = document.querySelector(".editor-cont-main")
      let curr = this.accurateCurrentTime

      if(!bar) return 'left: 20px;'

      const width = bar.offsetWidth
      const progress = curr - this.options.starttime
  
      if(progress < 0) return 'width: 0px;'

      const widthInPixels = Math.min(
        width,
        parseInt(progress / (this.options.endtime - this.options.starttime) * width)
      )

      return `left: ${widthInPixels + 20}px;`
    },
    fullOrDash() {
      if(this.selected_shape_name) {
        if(this.selected_shape.dash) {
          return this.selected_shape.dash.length > 0 ? 'dashed' : 'full'
        }
        return 'full'
      }
      return this.new_style
    }
  },
  methods: {
    ...mapActions('clip', [
      'clipById',
      'editClipStartAndEndtime',
      'deleteClip'
    ]),
    ...mapActions('folder', [
      'addFolder'
    ]),
    ...mapActions('noti', [
      'success',
      'error'
    ]),
    ...mapActions('player', [
      'seek',
      'pause',
      'play',
      'changePlaybackRate'
    ]),
    ...mapActions('draw', [
      'changeShapeColor',
      'putClipGraphics',
      'removePauseTimeout',
      'setPauseTimeout',
      'layerClicked',
      'removeObject'
    ]), 
    ...mapMutations('player', [
      'ADD_LAYER_PAUSED'
    ]),
    ...mapMutations('draw', [
      'SET_CLIP',
      'CLEAR',
      'SET_OBJECTS',
      'SET_LAYERS',
      'SET_OPTIONS',  
      'ADD_LAYER',
      'ADD_OBJECT',
      'SET_NEW_COLOR',
      'REMOVE_LAYER_BY_ID',
      'REMOVE_LAYER_OBJECTS_BY_ID',
      'SET_ORIGINAL_VIDEO_WIDTH',
      'SET_PAUSE_TIMEOUT',
      'SET_CHOSEN_OBJECT',
      'SET_SELECTED_SHAPE_NAME',
      'EDIT_OBJECT',
      'SET_LAYER_ID',
      'REFRESH_EDITOR_CONT_ITEM_WIDTH',
      'SET_CHOSEN_FOLDER',
      'SET_NEW_STROKE_WIDTH',
      'SET_NEW_TEXT',
      'SET_NEW_FILL_ENABLED',
      'SET_NEW_FONT_SIZE',
      'SET_NEW_STYLE'
    ]),
    startExporting() {
      this.initialising_exporting = true
      this.exporting = true
    },
    backClicked() {
      this.$router.push({
        path: this.from,
        query: {
          open_clip_id: this.clip.id
        }
      })
    },
    copyItem() {
      if(this.layer_id !== this.selected_shape.layer_id) return
      this.copied_item = this.selected_shape || null
    },
    downloadFrame() {
      this.$refs.editorPlayer.exportFrame()
    },
    pasteItem() {
      if(!this.copied_item) return
      const new_item = { ...this.copied_item, name: uuidv4() }
      this.ADD_OBJECT(new_item)
    },
    addClipToFolder(data) {
      if(!data) return

      this.addFolder({
        parent: data.id,
        name: this.clip.title,
        type: 'clip',
        clip_id: this.clip.id
      })
      .then(() => {
        this.delete_if_no_graphics = false
        this.success(this.$t('added_to_folder'))
      })
      .catch(e => this.error(e))
    },
    showEditor(property) { // stroke, strokeWidth, fillEnabled, fillColor, opacity, fontSize, fontWeight
      if(!this.chosen_object && !this.selected_shape_name) return false

      switch(property) {
        case 'strokeWidth':
          if (
            ( 
              this.chosen_object == 'line' ||
              this.chosen_object == 'arrow' ||
              this.chosen_object == 'ellipse' ||
              this.chosen_object == 'free' ||
              this.selected_shape.type == 'line' ||
              this.selected_shape.type == 'arrow' ||
              this.selected_shape.type == 'ellipse' || 
              this.selected_shape.type == 'free'
            )
          ) return true
          break;
        case 'text':
          if (
            this.chosen_object == 'text' || this.selected_shape.type == 'text'
          ) return true
          break;
        case 'fillEnabled':
          if (
            this.chosen_object == 'ellipse' || this.selected_shape.type == 'ellipse'
          ) return true
          break;
        case 'fontSize':
          if (
            this.chosen_object == 'text' || this.selected_shape.type == 'text'
          ) return true
          break; 
        case 'style':
        if (
          ( 
            this.chosen_object == 'line' ||
            this.chosen_object == 'arrow' ||
            this.chosen_object == 'ellipse' ||
            this.chosen_object == 'free' ||
            this.selected_shape.type == 'line' ||
            this.selected_shape.type == 'arrow' ||
            this.selected_shape.type == 'ellipse' ||
            this.selected_shape.type == 'free' 
          )
        ) return true
        break;
      }

      return false 
    },
    objectEdited(value, property) {
      if(this.selected_shape_name) {
        let object = {...this.selected_shape}
        if(value == 'dashed') value = [5, 10]
        if(value == 'full') value = []
        object[property] = value
        
        this.EDIT_OBJECT(object)
      }

      switch(property) { // stroke, strokeWidth, fillEnabled, fillColor, opacity, font_size
        case 'strokeWidth':
          this.SET_NEW_STROKE_WIDTH(value)
          break;
        case 'text':
          this.SET_NEW_TEXT(value)
          break;
        case 'fillEnabled': 
          this.SET_NEW_FILL_ENABLED(value)
          break;
        case 'fontSize': 
          this.SET_NEW_FONT_SIZE(value)
          break;
        case 'dash':
          if(this.selected_shape_name.length > 0) return
          this.SET_NEW_STYLE(value)
          break;
      } 
    },
    shapeAdded(e) {
      // e is an array of objects
      if(this.layer_id) return
      
      this.pause()
      this.seek(this.accurateCurrentTime)

      const id = uuidv4() 

      const newLayer = {
        id,
        starttime: this.accurateCurrentTime,
        endtime: null,
        still: true,
        duration: null
      }

      // add layer_id to the new shape
      // e['layer_id'] = id
      // this.ADD_OBJECT(e)
      if(e.length) {
        e.forEach(a => {
          a['layer_id'] = id
        })
      } else {
        e['layer_id'] = id
      }
      
      this.ADD_LAYER(newLayer)
      this.ADD_LAYER_PAUSED(newLayer.id)
      this.SET_LAYER_ID(id)
    },
    handleRangeChange(e) {
      const starttime = Math.max(this.clip.starttime - e[0], 0)
      if(starttime > this.clip.endtime - 1) return

      const endtime = this.clip.endtime ? this.clip.endtime + e[1] : this.clip.starttime + e[1]
      if(endtime < this.clip.starttime + 1) return

      // Ticks 
      this.options.starttime = Math.min(starttime, endtime - 1)
      this.options.endtime = Math.max(starttime + 1, endtime)

      this.$nextTick(() => {
        this.playerKey++
      })
    },
    deleteLayer() {
      this.REMOVE_LAYER_BY_ID(this.layer_id)
      this.REMOVE_LAYER_OBJECTS_BY_ID(this.layer_id)

      this.$nextTick(() => {
        this.SET_LAYER_ID(null)
      })
    },
    addText() {
      this.SET_CHOSEN_OBJECT('text')
      // this.$refs.editorPlayer.handleStageMouseDown()

      // let objects = [{
      //   "type": "text",
      //   "text": "New text",
      //   "id": Math.random().toString(),
      //   "data": [ 0.5, 0.5 ],
      //   "color": this.new_color
      // }]

      // if(this.layer_id) {
      //   let mod = {...this.fullLayerEdited}
      //   let i = this.layers?.findIndex(l => l.id == this.layer_id)
      //   mod['objects'] = mod['objects'].concat(objects)

      //   this.layers[i] = mod

      //   this.pause()

      //   return 
      // } else {
      //   let id = uuidv4()

      //   let item = {
      //     id,
      //     "starttime": this.currentTime,
      //     "endtime": null,
      //     "still": true,
      //     "duration": 3, 
      //     objects
      //   }
  
      //   this.ADD_LAYER(item)
  
      //   this.layer_id = id
      //   this.pause() 
      // }
    },
    speedEdited(speed) {
      let layer = this.layers.find(i => i.id == this.layer_id)
      layer = { ...layer, speed }
      this.editLayerData(layer)
    },
    durationEdited(duration) {
      let layer = this.layers.find(i => i.id == this.layer_id)
      layer = { ...layer, duration }
      this.editLayerData(layer)
    },
    editLayerData(lay) {
      let mod = [...this.layers]
      let i = this.layers.findIndex(i => i.id == this.layer_id)

      mod[i] = lay

      this.SET_LAYERS(mod)
    },
    colorEdited(e) {
      let color = e.hex || e
      this.SET_NEW_COLOR(color)
      this.changeShapeColor(color)

      return e
    },
    addSlowdown() {
      let id = uuidv4()

      this.ADD_LAYER({
        name: id,
        id,
        type: 'slowdown',
        starttime: this.currentTime,
        endtime: Math.min(this.currentTime + 5, this.options.endtime),
        speed: 0.5
      })
    },
    saveClip() {
      const firstPromise = this.$route.query.clip_id ? this.editClipStartAndEndtime({
        starttime: this.options.starttime,
        endtime: this.options.endtime,
        id: this.$route.query.clip_id
      }) : 1;

      const secondPromise = this.putClipGraphics()

      Promise.all([firstPromise, secondPromise])
        .then(() => {
          this.success('Drawing saved!')
          try {
            window.productFruits?.api?.checklists?.markItemAsDone('draw', true);
          } catch(e) {
            console.log(e)
          }
        })
        .catch(e => this.error(e))
    }
  },
  beforeDestroy() {
    const going_to_path = this.$route

    if(!this.objects?.length && !this.layers?.length && this.delete_if_no_graphics && going_to_path != 'editor') {
      this.deleteClip(this.clip.id)
    } else { 
      this.saveClip()
    }

    this.$nextTick(() => {
      this.CLEAR()
    })
  }
}
</script>

<style lang="scss" scoped>
  .editor-main {
    &-sidebar {
      width: 250px;
      height: calc(100vh - 260px);
      border: 1px solid black;
      border-radius: 0 5px 5px 0;
      padding: 20px;
      display: flex;
      flex-direction: column;

      &-cont {
        height: 100%;
        flex-grow: 1;

        &-item {
          flex-grow: 1;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        // &-item {

        // }
      }
    }
  }

  .toolbar {
    width: 100%;
    display: flex;
    justify-content: center;

    // &-divider {
    //   height: 40px;
    //   width: 3px;
    //   // color: lightgray;
    //   background-color: grey;
    //   padding-top: 20px;
    //   border-radius: 3px;
    //   display: inline-block;
    // }
  }
</style>