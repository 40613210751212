<template>
  <v-dialog @click:outside="$emit('close')" max-width="800" :value="show">
    <v-card>
      <v-card-title>
      {{$t('editor.settings')}}
      </v-card-title>
      <v-card-text>
        <v-slider
          :value="value[0]"
          :min="-30"
          :max="30"
          @input="changeStart($event)"
          :label="`${$t('start')} ${value[0]}s`"
        ></v-slider>
        <v-slider
          :min="-30"
          :max="30"
          :value="value[1]"
          @input="changeEnd($event)"
          :label="`${$t('end')} ${value[1]}s`"
        ></v-slider>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="$emit('close')"
        >
          {{ $t('ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['value', 'show'],
  methods: {
    changeStart(e) {
      this.$emit('input', [e, this.value[1]])
    },
    changeEnd(e) {
      this.$emit('input', [this.value[0], e])
    }
  }
}
</script>