<template>
  <v-card
    class="io-folder"
    outlined
    :ripple="false"
    @click.stop="$router.push(`/filter/${filter.id}`)"
  >
    <v-card-title
      class="io-folder-text"
    >
      <v-icon class="mr-5">mdi-filter</v-icon>
      {{filter.title}}
      <v-spacer></v-spacer>
      <v-menu
        offset-y
        class="mt-2"
        dense
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            fab
            x-small
            text
          >
            <v-icon size="20">
              mdi-dots-vertical
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <!-- <v-list-item
            @click="adding = true"
            link
            dense
          >
            <v-list-item-icon>
              <v-icon>mdi-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t('analysis.add_to_folder')}}</v-list-item-title>
          </v-list-item> -->
          <v-list-item
            @click="renaming = true; newName = filter.title;"
            dense
          >
            <v-list-item-icon>
              <v-icon>mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t('rename')}}</v-list-item-title>
          </v-list-item>
          <v-list-item 
            @click.stop="choosingFolder = true"
            link
          >
            <v-list-item-icon>
              <v-icon>mdi-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t('analysis.add_to_folder')}}</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            @click="deleting = true"
            link
            dense
          >
            <v-list-item-icon>
              <v-icon>mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{$t('delete')}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-dialog max-width="600" v-model="renaming">
      <v-card
        max-width="600"
        class="pt-10"
      >
        <v-card-text>
          <v-text-field
            v-model="newName"
            solo
            autofocus
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="renaming = false; newName = ''">{{$t('cancel')}}</v-btn>
          <v-spacer></v-spacer>
          <v-btn 
            color="primary" 
            @click="rename()"
            :disabled="!newName || newName == filter.title"
          > {{$t('rename')}} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirmation-dialog
      :show="deleting"
      :text="$t('filter.delete_filter')"
      :subText="$t('filter.sure_delete', [filter.title])"
      @decline="deleting = false"
      @accept="confirmDeleteFilter()"
      btnColor="red"
      :btnText="$t('delete')"
    ></confirmation-dialog>
    <choose-folder
      :visible="choosingFolder"
      :value="folder"
      @closed="choosingFolder = false"
      @chosen="addToFolder($event)"
      :text="$t('analysis.add_to_folder')"
    ></choose-folder>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import ConfirmationDialog from '../ConfirmationDialog.vue'
import ChooseFolder from '../folder/ChooseFolder.vue'
export default {
  props: ['filter'],
  components: {
    ConfirmationDialog,
    ChooseFolder
  },
  data: () => ({
    renaming: false,
    newName: '',
    deleting: false,
    choosingFolder: false,
    folder: null
  }),
  methods: {
    rename() {
      if(!this.newName) return
      this.updateTitle({
        ...this.filter,
        title: this.newName
      })
      .then(() => {
        this.success(this.$t('filter.filter_renamed'))
        this.renaming = false
      })
      .catch(e => this.error(e))
    },
    addToFolder(folder) {
      this.addFolder({
        parent: folder.id,
        name: this.filter.title || 'No title',
        type: 'filter',
        filter_id: this.filter.id
      })
      .then(() => {
        this.success(this.$t('clips.clip_added'))
        this.choosingFolder = false
        this.folder = null
      })
      .catch(e => this.error(e))
    },
    confirmDeleteFilter() {
      this.deleteFilter(this.filter.id)
        .then(() => {
          this.info(this.$t('filter.filter_deleted'))
          this.deleting = false
        })
        .catch(e => this.error(e))
    },
    ...mapActions('filter', [
      'updateTitle',
      'deleteFilter'
    ]),
    ...mapActions('folder', [
      'addFolder'
    ]),
    ...mapActions('noti', [
      'error',
      'success',
      'info'
    ])
  }
}
</script>