<template>
  <v-container style="height: 100%; overflow: auto; max-width: 1200px; margin: 0 auto;" :class="`hideScroll ${$vuetify.theme.dark? 'dark' : ''}`">
    <div class="pt-5">
      <v-btn
        fab
        large
        color="primary"
        @click="addFilterDialog = true"
      >
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>  
    </div>
    <v-row class="mt-6">
      <v-col
        cols="12"
        sm="6"
        md="3"
        v-for="filter in filters"
        :key="'filteritem' + filter.id"
      >
        <filter-item
          :filter="filter"
        ></filter-item>
      </v-col>
    </v-row>
    <div
      v-if="filters.length == 0"
      style="text-align: center;"
    >
      <v-icon size="100">mdi-filter-outline</v-icon>
      <h2>{{$t('filters.no_filters')}}</h2>
      <v-btn
        @click="addFilterDialog = true" 
        class="mt-6" 
        color="primary"
      >
        {{$t('filters.add_filter')}}
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
    <create-filter
      :loading="saving"
      :show="addFilterDialog"
      @close="addFilterDialog = false"
      @search="search($event)"
    ></create-filter>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CreateFilter from '../components/filters/CreateFilter.vue'
import FilterItem from '../components/filters/FilterItem.vue'
export default {
  components: {
    CreateFilter,
    FilterItem
  },
  created() {
    this.initFilters()
      .catch(e => this.error(e))
  },
  data: () => ({
    addFilterDialog: false,
    saving: false
  }),
  methods: {
    search(filter) {
      let filterData = {
        ...filter,
        league_id: this.leagueId,
        include_videos: false,
        include_clips: true,
        include_times: false
      }
      // console.log({
      //   ...filter,
      //   league_id: this.leagueId,
      //   include_videos: false,
      //   include_clips: true,
      //   include_times: false
      // })
      if(!filter.save) {
        this.$router.push(`/filter/nosave?filter=${JSON.stringify(filterData)}`)
        return
      }

      this.postFilter(filterData)
        .then(e => {
          this.addFilterDialog = false
          this.success(this.$t('filter.filter_saved'))
          this.$router.push(`/filter/${e.data.id}`)
        })
        .catch(e => this.error(e))
        .finally(() => {this.saving = false})
    },
    ...mapActions('filter', [
      'postFilter',
      'initFilters'
    ]),
    ...mapActions('noti', [
      'error',
      'success'
    ])
  },
  computed: {
    ...mapGetters('filter', [
      'filters'
    ]),
    ...mapGetters('tag', [
      'tag_groups'
    ]),
    ...mapGetters('user', [
      'leagueId'
    ])
  }
}
</script>

<style scoped>
.dark {
  background-color: #121212;
}
</style>